
*, ::after, ::before {
    box-sizing: border-box;
}

header, nav {
    display: block;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul ul {
    margin-bottom: 0;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #FFAD2F;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

.nhbc{
    width: 155px;
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-angle-right:before {
    content: "\f105";
}

.slicknav_btn, .slicknav_nav .slicknav_item {
    cursor: pointer;
}

.slicknav_menu, .slicknav_menu * {
    box-sizing: border-box;
}

.slicknav_btn {
    position: relative;
    display: block;
    vertical-align: middle;
    float: right;
    padding: .438em .625em;
    line-height: 1.125em;
}

.slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: .188em;
}

.slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left;
    color: #fff;
    font-weight: 700;
    text-shadow: 0 1px 3px #000;
}

.slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: .875em;
    margin: .188em 0 0 .438em;
}

.slicknav_menu .slicknav_icon:before {
    background: 0 0;
    width: 1.125em;
    height: .875em;
    display: block;
    content: "";
    position: absolute;
}

.slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: .125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, .25);
}

.slicknav_menu:after, .slicknav_menu:before {
    content: " ";
    display: table;
}

.slicknav_menu:after {
    clear: both;
}

.slicknav_nav li, .slicknav_nav ul {
    display: block;
}

.slicknav_nav .slicknav_arrow {
    font-size: .8em;
    margin: 0 0 0 .4em;
}

.slicknav_nav .slicknav_item a {
    display: inline;
}

.slicknav_nav .slicknav_row, .slicknav_nav a {
    display: block;
}

.slicknav_menu {
    *zoom: 1;
    font-size: 16px;
    background: #4c4c4c;
    padding: 5px;
}

.slicknav_nav, .slicknav_nav ul {
    list-style: none;
    overflow: hidden;
    padding: 0;
}

.slicknav_menu .slicknav_icon-bar {
    background-color: #fff;
}

.slicknav_btn {
    margin: 5px 5px 6px;
    text-decoration: none;
    text-shadow: 0 1px 1px rgba(255, 255, 255, .75);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #222;
}

.slicknav_nav {
    clear: both;
    color: #fff;
    margin: 0;
    font-size: .875em;
}

.slicknav_nav ul {
    margin: 0 0 0 20px;
}

.slicknav_nav .slicknav_row, .slicknav_nav a {
    padding: 5px 10px;
    margin: 2px 5px;
}

.slicknav_nav .slicknav_row:hover {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #ccc;
    color: #fff;
}

.slicknav_nav a {
    text-decoration: none;
    color: #fff;
}

.slicknav_nav a:hover {
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    background: #ccc;
    color: #222;
}

.slicknav_nav .slicknav_item a {
    padding: 0;
    margin: 0;
}

/*! CSS Used from: http://localhost:63342/g13/howitshouldbe/css/style.css */
img {
    max-width: 100%;
}

a:hover, a:focus {
    text-decoration: none;
    outline: none;
}

ul {
    padding: 0;
    margin: 0;
}

.header-section {
    display: block;
    width: 100%;
    left: 0;
    top: 0;
    padding: 0 53px;
    min-height: 110px;
    /* background: rgba(0, 0, 0, 0.7);
     */
    background: #22242b;

    z-index: 999;
}

.header-nav {
    text-align: right;
    padding-top: 35px;
}

.header-nav ul,li{
    list-style: none;
}
.site-logo {
    float: left;
    display: inline-block;
    padding: 15px 0;
    height: 100px;
    width: 110px;
}


.newNav {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

}
.newNavMobile{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

}
.MobileButton{
    color: white;
    font-size: 18px;
}

@media (max-width: 786px) {
    .newNav{
        display: none;
    }


}
@media (min-width: 786px) {
    .MobileButton{
        display: none;
    }
}

.newNavLi{
    padding: 20px;

}

.newNavLiA{
    display: block;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
    font-family: "Lato", sans-serif;
    padding: 0 1px 5px;
    border-bottom: 2px solid transparent;
    margin-right: 45px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.newNavLiA:hover {
    border-bottom: 2px solid #FFAD2F;
}

.main-menu {
    display: inline-block;
    padding-right: 30px;
}

.main-menu li {
    display: inline-block;
    position: relative;
}

.main-menu li a {
    display: block;
    font-size: 16px;
    color: #ffffff;
    font-family: "Lato", sans-serif;
    padding: 0 1px 5px;
    border-bottom: 2px solid transparent;
    margin-right: 45px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.main-menu li a:hover, .main-menu li a.active {
    border-bottom: 2px solid #FFAD2F;
}

.main-menu li:hover > .sub-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 20px;
}

.main-menu .sub-menu {
    position: absolute;
    list-style: none;
    width: 220px;
    left: 0;
    top: 100%;
    text-align: left;
    padding: 20px 0;
    visibility: hidden;
    opacity: 0;
    margin-top: 50px;
    background: #fff;
    z-index: 99;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.15);
    box-shadow: 2px 7px 20px rgba(0, 0, 0, 0.15);
}

.main-menu .sub-menu::before {
    position: absolute;
    content: "";
    left: 15px;
    top: -7px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 7px solid #fff;
}

.main-menu .sub-menu:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 20px;
    left: 0;
    top: -20px;
}

.main-menu .sub-menu li {
    display: block;
}

.main-menu .sub-menu li a {
    display: block;
    color: #000;
    margin-right: 0;
    padding: 5px 20px;
    border-bottom: none;
}

.main-menu .sub-menu li a:hover {
    color: #FFAD2F;
}

.header-right {
    display: inline-block;
}

.header-right a {
    color: #fff;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    display: inline-block;
}

.header-right .register {
    border: 2px solid #fff;
    min-width: 130px;
    text-align: center;
    padding: 8px 15px;
    margin-left: 25px;
}

.slicknav_menu {
    display: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .main-menu li a {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .header-section {
        padding: 0 15px;
    }

    .main-menu {
        padding-right: 15px;
    }

    .main-menu li a {
        margin-right: 15px;
    }

    .header-right .register {
        min-width: 110px;
        margin-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .header-section {
        padding: 0 15px;
    }

    .site-logo {
        padding: 26px 0;
    }


    .slicknav_menu {
        padding: 0;
        display: block;
        background: transparent;
    }

    .slicknav_menu .header-right-warp {
        padding: 10px 0;
        padding-right: 10px;
        border-bottom: 1px solid #111;
        text-align: right;
    }

    .slicknav_menu .header-right .login {
        border-bottom: none;
    }

    .slicknav_menu .header-right .register {
        margin-left: 0;
    }

    .slicknav_menu .header-right a:hover {
        background: transparent;
        color: #fff;
    }

    .slicknav_btn {
        background: #616161;
        margin-top: 29px;
    }

    .slicknav_nav {
        background: #22242b;
        margin-bottom: 30px;
    }

    .slicknav_nav a {
        margin: 0;
        padding: 10px 20px;
        border-bottom: 1px solid #111;
    }

    .slicknav_nav a:hover {
        color: #FFAD2F;
        border-radius: 0;
        background: #0b0e25;
    }

    .slicknav_nav .slicknav_row {
        padding: 10px 20px;
        margin: 0;
    }

    .slicknav_row a {
        border-bottom: none;
    }

    .slicknav_nav .slicknav_row:hover {
        border-radius: 0;
        background: #0b0e25;
    }
}


@font-face {
    font-family: 'FontAwesome';
    src: url('./icon-fonts/fontawesome-webfont.eot?v=4.7.0');
    src: url('./icon-fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'), url('./icon-fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'), url('./icon-fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'), url('./icon-fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.fa-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%
}

.fa-2x {
    font-size: 2em
}

.fa-3x {
    font-size: 3em
}

.fa-4x {
    font-size: 4em
}

.fa-5x {
    font-size: 5em
}

.fa-fw {
    width: 1.28571429em;
    text-align: center
}

.fa-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none
}

.fa-ul > li {
    position: relative
}

.fa-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center
}

.fa-li.fa-lg {
    left: -1.85714286em
}

.fa-border {
    padding: .2em .25em .15em;
    border: solid .08em #eee;
    border-radius: .1em
}

.fa-pull-left {
    float: left
}

.fa-pull-right {
    float: right
}

.fa.fa-pull-left {
    margin-right: .3em
}

.fa.fa-pull-right {
    margin-left: .3em
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.fa.pull-left {
    margin-right: .3em
}

.fa.pull-right {
    margin-left: .3em
}

.fa-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear
}

.fa-pulse {
    -webkit-animation: fa-spin 1s infinite steps(8);
    animation: fa-spin 1s infinite steps(8)
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg)
    }
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg)
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1)
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    -webkit-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1)
}

:root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270, :root .fa-flip-horizontal, :root .fa-flip-vertical {
    filter: none
}

.fa-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle
}

.fa-stack-1x, .fa-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center
}

.fa-stack-1x {
    line-height: inherit
}

.fa-stack-2x {
    font-size: 2em
}

.fa-inverse {
    color: #fff
}

.fa-glass:before {
    content: "\f000"
}

.fa-music:before {
    content: "\f001"
}

.fa-search:before {
    content: "\f002"
}

.fa-envelope-o:before {
    content: "\f003"
}

.fa-heart:before {
    content: "\f004"
}

.fa-star:before {
    content: "\f005"
}

.fa-star-o:before {
    content: "\f006"
}

.fa-user:before {
    content: "\f007"
}

.fa-film:before {
    content: "\f008"
}

.fa-th-large:before {
    content: "\f009"
}

.fa-th:before {
    content: "\f00a"
}

.fa-th-list:before {
    content: "\f00b"
}

.fa-check:before {
    content: "\f00c"
}

.fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d"
}

.fa-search-plus:before {
    content: "\f00e"
}

.fa-search-minus:before {
    content: "\f010"
}

.fa-power-off:before {
    content: "\f011"
}

.fa-signal:before {
    content: "\f012"
}

.fa-gear:before, .fa-cog:before {
    content: "\f013"
}

.fa-trash-o:before {
    content: "\f014"
}

.fa-home:before {
    content: "\f015"
}

.fa-file-o:before {
    content: "\f016"
}

.fa-clock-o:before {
    content: "\f017"
}

.fa-road:before {
    content: "\f018"
}

.fa-download:before {
    content: "\f019"
}

.fa-arrow-circle-o-down:before {
    content: "\f01a"
}

.fa-arrow-circle-o-up:before {
    content: "\f01b"
}

.fa-inbox:before {
    content: "\f01c"
}

.fa-play-circle-o:before {
    content: "\f01d"
}

.fa-rotate-right:before, .fa-repeat:before {
    content: "\f01e"
}

.fa-refresh:before {
    content: "\f021"
}

.fa-list-alt:before {
    content: "\f022"
}

.fa-lock:before {
    content: "\f023"
}

.fa-flag:before {
    content: "\f024"
}

.fa-headphones:before {
    content: "\f025"
}

.fa-volume-off:before {
    content: "\f026"
}

.fa-volume-down:before {
    content: "\f027"
}

.fa-volume-up:before {
    content: "\f028"
}

.fa-qrcode:before {
    content: "\f029"
}

.fa-barcode:before {
    content: "\f02a"
}

.fa-tag:before {
    content: "\f02b"
}

.fa-tags:before {
    content: "\f02c"
}

.fa-book:before {
    content: "\f02d"
}

.fa-bookmark:before {
    content: "\f02e"
}

.fa-print:before {
    content: "\f02f"
}

.fa-camera:before {
    content: "\f030"
}

.fa-font:before {
    content: "\f031"
}

.fa-bold:before {
    content: "\f032"
}

.fa-italic:before {
    content: "\f033"
}

.fa-text-height:before {
    content: "\f034"
}

.fa-text-width:before {
    content: "\f035"
}

.fa-align-left:before {
    content: "\f036"
}

.fa-align-center:before {
    content: "\f037"
}

.fa-align-right:before {
    content: "\f038"
}

.fa-align-justify:before {
    content: "\f039"
}

.fa-list:before {
    content: "\f03a"
}

.fa-dedent:before, .fa-outdent:before {
    content: "\f03b"
}

.fa-indent:before {
    content: "\f03c"
}

.fa-video-camera:before {
    content: "\f03d"
}

.fa-photo:before, .fa-image:before, .fa-picture-o:before {
    content: "\f03e"
}

.fa-pencil:before {
    content: "\f040"
}

.fa-map-marker:before {
    content: "\f041"
}

.fa-adjust:before {
    content: "\f042"
}

.fa-tint:before {
    content: "\f043"
}

.fa-edit:before, .fa-pencil-square-o:before {
    content: "\f044"
}

.fa-share-square-o:before {
    content: "\f045"
}

.fa-check-square-o:before {
    content: "\f046"
}

.fa-arrows:before {
    content: "\f047"
}

.fa-step-backward:before {
    content: "\f048"
}

.fa-fast-backward:before {
    content: "\f049"
}

.fa-backward:before {
    content: "\f04a"
}

.fa-play:before {
    content: "\f04b"
}

.fa-pause:before {
    content: "\f04c"
}

.fa-stop:before {
    content: "\f04d"
}

.fa-forward:before {
    content: "\f04e"
}

.fa-fast-forward:before {
    content: "\f050"
}

.fa-step-forward:before {
    content: "\f051"
}

.fa-eject:before {
    content: "\f052"
}

.fa-chevron-left:before {
    content: "\f053"
}

.fa-chevron-right:before {
    content: "\f054"
}

.fa-plus-circle:before {
    content: "\f055"
}

.fa-minus-circle:before {
    content: "\f056"
}

.fa-times-circle:before {
    content: "\f057"
}

.fa-check-circle:before {
    content: "\f058"
}

.fa-question-circle:before {
    content: "\f059"
}

.fa-info-circle:before {
    content: "\f05a"
}

.fa-crosshairs:before {
    content: "\f05b"
}

.fa-times-circle-o:before {
    content: "\f05c"
}

.fa-check-circle-o:before {
    content: "\f05d"
}

.fa-ban:before {
    content: "\f05e"
}

.fa-arrow-left:before {
    content: "\f060"
}

.fa-arrow-right:before {
    content: "\f061"
}

.fa-arrow-up:before {
    content: "\f062"
}

.fa-arrow-down:before {
    content: "\f063"
}

.fa-mail-forward:before, .fa-share:before {
    content: "\f064"
}

.fa-expand:before {
    content: "\f065"
}

.fa-compress:before {
    content: "\f066"
}

.fa-plus:before {
    content: "\f067"
}

.fa-minus:before {
    content: "\f068"
}

.fa-asterisk:before {
    content: "\f069"
}

.fa-exclamation-circle:before {
    content: "\f06a"
}

.fa-gift:before {
    content: "\f06b"
}

.fa-leaf:before {
    content: "\f06c"
}

.fa-fire:before {
    content: "\f06d"
}

.fa-eye:before {
    content: "\f06e"
}

.fa-eye-slash:before {
    content: "\f070"
}

.fa-warning:before, .fa-exclamation-triangle:before {
    content: "\f071"
}

.fa-plane:before {
    content: "\f072"
}

.fa-calendar:before {
    content: "\f073"
}

.fa-random:before {
    content: "\f074"
}

.fa-comment:before {
    content: "\f075"
}

.fa-magnet:before {
    content: "\f076"
}

.fa-chevron-up:before {
    content: "\f077"
}

.fa-chevron-down:before {
    content: "\f078"
}

.fa-retweet:before {
    content: "\f079"
}

.fa-shopping-cart:before {
    content: "\f07a"
}

.fa-folder:before {
    content: "\f07b"
}

.fa-folder-open:before {
    content: "\f07c"
}

.fa-arrows-v:before {
    content: "\f07d"
}

.fa-arrows-h:before {
    content: "\f07e"
}

.fa-bar-chart-o:before, .fa-bar-chart:before {
    content: "\f080"
}

.fa-twitter-square:before {
    content: "\f081"
}

.fa-facebook-square:before {
    content: "\f082"
}

.fa-camera-retro:before {
    content: "\f083"
}

.fa-key:before {
    content: "\f084"
}

.fa-gears:before, .fa-cogs:before {
    content: "\f085"
}

.fa-comments:before {
    content: "\f086"
}

.fa-thumbs-o-up:before {
    content: "\f087"
}

.fa-thumbs-o-down:before {
    content: "\f088"
}

.fa-star-half:before {
    content: "\f089"
}

.fa-heart-o:before {
    content: "\f08a"
}

.fa-sign-out:before {
    content: "\f08b"
}

.fa-linkedin-square:before {
    content: "\f08c"
}

.fa-thumb-tack:before {
    content: "\f08d"
}

.fa-external-link:before {
    content: "\f08e"
}

.fa-sign-in:before {
    content: "\f090"
}

.fa-trophy:before {
    content: "\f091"
}

.fa-github-square:before {
    content: "\f092"
}

.fa-upload:before {
    content: "\f093"
}

.fa-lemon-o:before {
    content: "\f094"
}

.fa-phone:before {
    content: "\f095"
}

.fa-square-o:before {
    content: "\f096"
}

.fa-bookmark-o:before {
    content: "\f097"
}

.fa-phone-square:before {
    content: "\f098"
}

.fa-twitter:before {
    content: "\f099"
}

.fa-facebook-f:before, .fa-facebook:before {
    content: "\f09a"
}

.fa-github:before {
    content: "\f09b"
}

.fa-unlock:before {
    content: "\f09c"
}

.fa-credit-card:before {
    content: "\f09d"
}

.fa-feed:before, .fa-rss:before {
    content: "\f09e"
}

.fa-hdd-o:before {
    content: "\f0a0"
}

.fa-bullhorn:before {
    content: "\f0a1"
}

.fa-bell:before {
    content: "\f0f3"
}

.fa-certificate:before {
    content: "\f0a3"
}

.fa-hand-o-right:before {
    content: "\f0a4"
}

.fa-hand-o-left:before {
    content: "\f0a5"
}

.fa-hand-o-up:before {
    content: "\f0a6"
}

.fa-hand-o-down:before {
    content: "\f0a7"
}

.fa-arrow-circle-left:before {
    content: "\f0a8"
}

.fa-arrow-circle-right:before {
    content: "\f0a9"
}

.fa-arrow-circle-up:before {
    content: "\f0aa"
}

.fa-arrow-circle-down:before {
    content: "\f0ab"
}

.fa-globe:before {
    content: "\f0ac"
}

.fa-wrench:before {
    content: "\f0ad"
}

.fa-tasks:before {
    content: "\f0ae"
}

.fa-filter:before {
    content: "\f0b0"
}

.fa-briefcase:before {
    content: "\f0b1"
}

.fa-arrows-alt:before {
    content: "\f0b2"
}

.fa-group:before, .fa-users:before {
    content: "\f0c0"
}

.fa-chain:before, .fa-link:before {
    content: "\f0c1"
}

.fa-cloud:before {
    content: "\f0c2"
}

.fa-flask:before {
    content: "\f0c3"
}

.fa-cut:before, .fa-scissors:before {
    content: "\f0c4"
}

.fa-copy:before, .fa-files-o:before {
    content: "\f0c5"
}

.fa-paperclip:before {
    content: "\f0c6"
}

.fa-save:before, .fa-floppy-o:before {
    content: "\f0c7"
}

.fa-square:before {
    content: "\f0c8"
}

.fa-navicon:before, .fa-reorder:before, .fa-bars:before {
    content: "\f0c9"
}

.fa-list-ul:before {
    content: "\f0ca"
}

.fa-list-ol:before {
    content: "\f0cb"
}

.fa-strikethrough:before {
    content: "\f0cc"
}

.fa-underline:before {
    content: "\f0cd"
}

.fa-table:before {
    content: "\f0ce"
}

.fa-magic:before {
    content: "\f0d0"
}

.fa-truck:before {
    content: "\f0d1"
}

.fa-pinterest:before {
    content: "\f0d2"
}

.fa-pinterest-square:before {
    content: "\f0d3"
}

.fa-google-plus-square:before {
    content: "\f0d4"
}

.fa-google-plus:before {
    content: "\f0d5"
}

.fa-money:before {
    content: "\f0d6"
}

.fa-caret-down:before {
    content: "\f0d7"
}

.fa-caret-up:before {
    content: "\f0d8"
}

.fa-caret-left:before {
    content: "\f0d9"
}

.fa-caret-right:before {
    content: "\f0da"
}

.fa-columns:before {
    content: "\f0db"
}

.fa-unsorted:before, .fa-sort:before {
    content: "\f0dc"
}

.fa-sort-down:before, .fa-sort-desc:before {
    content: "\f0dd"
}

.fa-sort-up:before, .fa-sort-asc:before {
    content: "\f0de"
}

.fa-envelope:before {
    content: "\f0e0"
}

.fa-linkedin:before {
    content: "\f0e1"
}

.fa-rotate-left:before, .fa-undo:before {
    content: "\f0e2"
}

.fa-legal:before, .fa-gavel:before {
    content: "\f0e3"
}

.fa-dashboard:before, .fa-tachometer:before {
    content: "\f0e4"
}

.fa-comment-o:before {
    content: "\f0e5"
}

.fa-comments-o:before {
    content: "\f0e6"
}

.fa-flash:before, .fa-bolt:before {
    content: "\f0e7"
}

.fa-sitemap:before {
    content: "\f0e8"
}

.fa-umbrella:before {
    content: "\f0e9"
}

.fa-paste:before, .fa-clipboard:before {
    content: "\f0ea"
}

.fa-lightbulb-o:before {
    content: "\f0eb"
}

.fa-exchange:before {
    content: "\f0ec"
}

.fa-cloud-download:before {
    content: "\f0ed"
}

.fa-cloud-upload:before {
    content: "\f0ee"
}

.fa-user-md:before {
    content: "\f0f0"
}

.fa-stethoscope:before {
    content: "\f0f1"
}

.fa-suitcase:before {
    content: "\f0f2"
}

.fa-bell-o:before {
    content: "\f0a2"
}

.fa-coffee:before {
    content: "\f0f4"
}

.fa-cutlery:before {
    content: "\f0f5"
}

.fa-file-text-o:before {
    content: "\f0f6"
}

.fa-building-o:before {
    content: "\f0f7"
}

.fa-hospital-o:before {
    content: "\f0f8"
}

.fa-ambulance:before {
    content: "\f0f9"
}

.fa-medkit:before {
    content: "\f0fa"
}

.fa-fighter-jet:before {
    content: "\f0fb"
}

.fa-beer:before {
    content: "\f0fc"
}

.fa-h-square:before {
    content: "\f0fd"
}

.fa-plus-square:before {
    content: "\f0fe"
}

.fa-angle-double-left:before {
    content: "\f100"
}

.fa-angle-double-right:before {
    content: "\f101"
}

.fa-angle-double-up:before {
    content: "\f102"
}

.fa-angle-double-down:before {
    content: "\f103"
}

.fa-angle-left:before {
    content: "\f104"
}

.fa-angle-right:before {
    content: "\f105"
}

.fa-angle-up:before {
    content: "\f106"
}

.fa-angle-down:before {
    content: "\f107"
}

.fa-desktop:before {
    content: "\f108"
}

.fa-laptop:before {
    content: "\f109"
}

.fa-tablet:before {
    content: "\f10a"
}

.fa-mobile-phone:before, .fa-mobile:before {
    content: "\f10b"
}

.fa-circle-o:before {
    content: "\f10c"
}

.fa-quote-left:before {
    content: "\f10d"
}

.fa-quote-right:before {
    content: "\f10e"
}

.fa-spinner:before {
    content: "\f110"
}

.fa-circle:before {
    content: "\f111"
}

.fa-mail-reply:before, .fa-reply:before {
    content: "\f112"
}

.fa-github-alt:before {
    content: "\f113"
}

.fa-folder-o:before {
    content: "\f114"
}

.fa-folder-open-o:before {
    content: "\f115"
}

.fa-smile-o:before {
    content: "\f118"
}

.fa-frown-o:before {
    content: "\f119"
}

.fa-meh-o:before {
    content: "\f11a"
}

.fa-gamepad:before {
    content: "\f11b"
}

.fa-keyboard-o:before {
    content: "\f11c"
}

.fa-flag-o:before {
    content: "\f11d"
}

.fa-flag-checkered:before {
    content: "\f11e"
}

.fa-terminal:before {
    content: "\f120"
}

.fa-code:before {
    content: "\f121"
}

.fa-mail-reply-all:before, .fa-reply-all:before {
    content: "\f122"
}

.fa-star-half-empty:before, .fa-star-half-full:before, .fa-star-half-o:before {
    content: "\f123"
}

.fa-location-arrow:before {
    content: "\f124"
}

.fa-crop:before {
    content: "\f125"
}

.fa-code-fork:before {
    content: "\f126"
}

.fa-unlink:before, .fa-chain-broken:before {
    content: "\f127"
}

.fa-question:before {
    content: "\f128"
}

.fa-info:before {
    content: "\f129"
}

.fa-exclamation:before {
    content: "\f12a"
}

.fa-superscript:before {
    content: "\f12b"
}

.fa-subscript:before {
    content: "\f12c"
}

.fa-eraser:before {
    content: "\f12d"
}

.fa-puzzle-piece:before {
    content: "\f12e"
}

.fa-microphone:before {
    content: "\f130"
}

.fa-microphone-slash:before {
    content: "\f131"
}

.fa-shield:before {
    content: "\f132"
}

.fa-calendar-o:before {
    content: "\f133"
}

.fa-fire-extinguisher:before {
    content: "\f134"
}

.fa-rocket:before {
    content: "\f135"
}

.fa-maxcdn:before {
    content: "\f136"
}

.fa-chevron-circle-left:before {
    content: "\f137"
}

.fa-chevron-circle-right:before {
    content: "\f138"
}

.fa-chevron-circle-up:before {
    content: "\f139"
}

.fa-chevron-circle-down:before {
    content: "\f13a"
}

.fa-html5:before {
    content: "\f13b"
}

.fa-css3:before {
    content: "\f13c"
}

.fa-anchor:before {
    content: "\f13d"
}

.fa-unlock-alt:before {
    content: "\f13e"
}

.fa-bullseye:before {
    content: "\f140"
}

.fa-ellipsis-h:before {
    content: "\f141"
}

.fa-ellipsis-v:before {
    content: "\f142"
}

.fa-rss-square:before {
    content: "\f143"
}

.fa-play-circle:before {
    content: "\f144"
}

.fa-ticket:before {
    content: "\f145"
}

.fa-minus-square:before {
    content: "\f146"
}

.fa-minus-square-o:before {
    content: "\f147"
}

.fa-level-up:before {
    content: "\f148"
}

.fa-level-down:before {
    content: "\f149"
}

.fa-check-square:before {
    content: "\f14a"
}

.fa-pencil-square:before {
    content: "\f14b"
}

.fa-external-link-square:before {
    content: "\f14c"
}

.fa-share-square:before {
    content: "\f14d"
}

.fa-compass:before {
    content: "\f14e"
}

.fa-toggle-down:before, .fa-caret-square-o-down:before {
    content: "\f150"
}

.fa-toggle-up:before, .fa-caret-square-o-up:before {
    content: "\f151"
}

.fa-toggle-right:before, .fa-caret-square-o-right:before {
    content: "\f152"
}

.fa-euro:before, .fa-eur:before {
    content: "\f153"
}

.fa-gbp:before {
    content: "\f154"
}

.fa-dollar:before, .fa-usd:before {
    content: "\f155"
}

.fa-rupee:before, .fa-inr:before {
    content: "\f156"
}

.fa-cny:before, .fa-rmb:before, .fa-yen:before, .fa-jpy:before {
    content: "\f157"
}

.fa-ruble:before, .fa-rouble:before, .fa-rub:before {
    content: "\f158"
}

.fa-won:before, .fa-krw:before {
    content: "\f159"
}

.fa-bitcoin:before, .fa-btc:before {
    content: "\f15a"
}

.fa-file:before {
    content: "\f15b"
}

.fa-file-text:before {
    content: "\f15c"
}

.fa-sort-alpha-asc:before {
    content: "\f15d"
}

.fa-sort-alpha-desc:before {
    content: "\f15e"
}

.fa-sort-amount-asc:before {
    content: "\f160"
}

.fa-sort-amount-desc:before {
    content: "\f161"
}

.fa-sort-numeric-asc:before {
    content: "\f162"
}

.fa-sort-numeric-desc:before {
    content: "\f163"
}

.fa-thumbs-up:before {
    content: "\f164"
}

.fa-thumbs-down:before {
    content: "\f165"
}

.fa-youtube-square:before {
    content: "\f166"
}

.fa-youtube:before {
    content: "\f167"
}

.fa-xing:before {
    content: "\f168"
}

.fa-xing-square:before {
    content: "\f169"
}

.fa-youtube-play:before {
    content: "\f16a"
}

.fa-dropbox:before {
    content: "\f16b"
}

.fa-stack-overflow:before {
    content: "\f16c"
}

.fa-instagram:before {
    content: "\f16d"
}

.fa-flickr:before {
    content: "\f16e"
}

.fa-adn:before {
    content: "\f170"
}

.fa-bitbucket:before {
    content: "\f171"
}

.fa-bitbucket-square:before {
    content: "\f172"
}

.fa-tumblr:before {
    content: "\f173"
}

.fa-tumblr-square:before {
    content: "\f174"
}

.fa-long-arrow-down:before {
    content: "\f175"
}

.fa-long-arrow-up:before {
    content: "\f176"
}

.fa-long-arrow-left:before {
    content: "\f177"
}

.fa-long-arrow-right:before {
    content: "\f178"
}

.fa-apple:before {
    content: "\f179"
}

.fa-windows:before {
    content: "\f17a"
}

.fa-android:before {
    content: "\f17b"
}

.fa-linux:before {
    content: "\f17c"
}

.fa-dribbble:before {
    content: "\f17d"
}

.fa-skype:before {
    content: "\f17e"
}

.fa-foursquare:before {
    content: "\f180"
}

.fa-trello:before {
    content: "\f181"
}

.fa-female:before {
    content: "\f182"
}

.fa-male:before {
    content: "\f183"
}

.fa-gittip:before, .fa-gratipay:before {
    content: "\f184"
}

.fa-sun-o:before {
    content: "\f185"
}

.fa-moon-o:before {
    content: "\f186"
}

.fa-archive:before {
    content: "\f187"
}

.fa-bug:before {
    content: "\f188"
}

.fa-vk:before {
    content: "\f189"
}

.fa-weibo:before {
    content: "\f18a"
}

.fa-renren:before {
    content: "\f18b"
}

.fa-pagelines:before {
    content: "\f18c"
}

.fa-stack-exchange:before {
    content: "\f18d"
}

.fa-arrow-circle-o-right:before {
    content: "\f18e"
}

.fa-arrow-circle-o-left:before {
    content: "\f190"
}

.fa-toggle-left:before, .fa-caret-square-o-left:before {
    content: "\f191"
}

.fa-dot-circle-o:before {
    content: "\f192"
}

.fa-wheelchair:before {
    content: "\f193"
}

.fa-vimeo-square:before {
    content: "\f194"
}

.fa-turkish-lira:before, .fa-try:before {
    content: "\f195"
}

.fa-plus-square-o:before {
    content: "\f196"
}

.fa-space-shuttle:before {
    content: "\f197"
}

.fa-slack:before {
    content: "\f198"
}

.fa-envelope-square:before {
    content: "\f199"
}

.fa-wordpress:before {
    content: "\f19a"
}

.fa-openid:before {
    content: "\f19b"
}

.fa-institution:before, .fa-bank:before, .fa-university:before {
    content: "\f19c"
}

.fa-mortar-board:before, .fa-graduation-cap:before {
    content: "\f19d"
}

.fa-yahoo:before {
    content: "\f19e"
}

.fa-google:before {
    content: "\f1a0"
}

.fa-reddit:before {
    content: "\f1a1"
}

.fa-reddit-square:before {
    content: "\f1a2"
}

.fa-stumbleupon-circle:before {
    content: "\f1a3"
}

.fa-stumbleupon:before {
    content: "\f1a4"
}

.fa-delicious:before {
    content: "\f1a5"
}

.fa-digg:before {
    content: "\f1a6"
}

.fa-pied-piper-pp:before {
    content: "\f1a7"
}

.fa-pied-piper-alt:before {
    content: "\f1a8"
}

.fa-drupal:before {
    content: "\f1a9"
}

.fa-joomla:before {
    content: "\f1aa"
}

.fa-language:before {
    content: "\f1ab"
}

.fa-fax:before {
    content: "\f1ac"
}

.fa-building:before {
    content: "\f1ad"
}

.fa-child:before {
    content: "\f1ae"
}

.fa-paw:before {
    content: "\f1b0"
}

.fa-spoon:before {
    content: "\f1b1"
}

.fa-cube:before {
    content: "\f1b2"
}

.fa-cubes:before {
    content: "\f1b3"
}

.fa-behance:before {
    content: "\f1b4"
}

.fa-behance-square:before {
    content: "\f1b5"
}

.fa-steam:before {
    content: "\f1b6"
}

.fa-steam-square:before {
    content: "\f1b7"
}

.fa-recycle:before {
    content: "\f1b8"
}

.fa-automobile:before, .fa-car:before {
    content: "\f1b9"
}

.fa-cab:before, .fa-taxi:before {
    content: "\f1ba"
}

.fa-tree:before {
    content: "\f1bb"
}

.fa-spotify:before {
    content: "\f1bc"
}

.fa-deviantart:before {
    content: "\f1bd"
}

.fa-soundcloud:before {
    content: "\f1be"
}

.fa-database:before {
    content: "\f1c0"
}

.fa-file-pdf-o:before {
    content: "\f1c1"
}

.fa-file-word-o:before {
    content: "\f1c2"
}

.fa-file-excel-o:before {
    content: "\f1c3"
}

.fa-file-powerpoint-o:before {
    content: "\f1c4"
}

.fa-file-photo-o:before, .fa-file-picture-o:before, .fa-file-image-o:before {
    content: "\f1c5"
}

.fa-file-zip-o:before, .fa-file-archive-o:before {
    content: "\f1c6"
}

.fa-file-sound-o:before, .fa-file-audio-o:before {
    content: "\f1c7"
}

.fa-file-movie-o:before, .fa-file-video-o:before {
    content: "\f1c8"
}

.fa-file-code-o:before {
    content: "\f1c9"
}

.fa-vine:before {
    content: "\f1ca"
}

.fa-codepen:before {
    content: "\f1cb"
}

.fa-jsfiddle:before {
    content: "\f1cc"
}

.fa-life-bouy:before, .fa-life-buoy:before, .fa-life-saver:before, .fa-support:before, .fa-life-ring:before {
    content: "\f1cd"
}

.fa-circle-o-notch:before {
    content: "\f1ce"
}

.fa-ra:before, .fa-resistance:before, .fa-rebel:before {
    content: "\f1d0"
}

.fa-ge:before, .fa-empire:before {
    content: "\f1d1"
}

.fa-git-square:before {
    content: "\f1d2"
}

.fa-git:before {
    content: "\f1d3"
}

.fa-y-combinator-square:before, .fa-yc-square:before, .fa-hacker-news:before {
    content: "\f1d4"
}

.fa-tencent-weibo:before {
    content: "\f1d5"
}

.fa-qq:before {
    content: "\f1d6"
}

.fa-wechat:before, .fa-weixin:before {
    content: "\f1d7"
}

.fa-send:before, .fa-paper-plane:before {
    content: "\f1d8"
}

.fa-send-o:before, .fa-paper-plane-o:before {
    content: "\f1d9"
}

.fa-history:before {
    content: "\f1da"
}

.fa-circle-thin:before {
    content: "\f1db"
}

.fa-header:before {
    content: "\f1dc"
}

.fa-paragraph:before {
    content: "\f1dd"
}

.fa-sliders:before {
    content: "\f1de"
}

.fa-share-alt:before {
    content: "\f1e0"
}

.fa-share-alt-square:before {
    content: "\f1e1"
}

.fa-bomb:before {
    content: "\f1e2"
}

.fa-soccer-ball-o:before, .fa-futbol-o:before {
    content: "\f1e3"
}

.fa-tty:before {
    content: "\f1e4"
}

.fa-binoculars:before {
    content: "\f1e5"
}

.fa-plug:before {
    content: "\f1e6"
}

.fa-slideshare:before {
    content: "\f1e7"
}

.fa-twitch:before {
    content: "\f1e8"
}

.fa-yelp:before {
    content: "\f1e9"
}

.fa-newspaper-o:before {
    content: "\f1ea"
}

.fa-wifi:before {
    content: "\f1eb"
}

.fa-calculator:before {
    content: "\f1ec"
}

.fa-paypal:before {
    content: "\f1ed"
}

.fa-google-wallet:before {
    content: "\f1ee"
}

.fa-cc-visa:before {
    content: "\f1f0"
}

.fa-cc-mastercard:before {
    content: "\f1f1"
}

.fa-cc-discover:before {
    content: "\f1f2"
}

.fa-cc-amex:before {
    content: "\f1f3"
}

.fa-cc-paypal:before {
    content: "\f1f4"
}

.fa-cc-stripe:before {
    content: "\f1f5"
}

.fa-bell-slash:before {
    content: "\f1f6"
}

.fa-bell-slash-o:before {
    content: "\f1f7"
}

.fa-trash:before {
    content: "\f1f8"
}

.fa-copyright:before {
    content: "\f1f9"
}

.fa-at:before {
    content: "\f1fa"
}

.fa-eyedropper:before {
    content: "\f1fb"
}

.fa-paint-brush:before {
    content: "\f1fc"
}

.fa-birthday-cake:before {
    content: "\f1fd"
}

.fa-area-chart:before {
    content: "\f1fe"
}

.fa-pie-chart:before {
    content: "\f200"
}

.fa-line-chart:before {
    content: "\f201"
}

.fa-lastfm:before {
    content: "\f202"
}

.fa-lastfm-square:before {
    content: "\f203"
}

.fa-toggle-off:before {
    content: "\f204"
}

.fa-toggle-on:before {
    content: "\f205"
}

.fa-bicycle:before {
    content: "\f206"
}

.fa-bus:before {
    content: "\f207"
}

.fa-ioxhost:before {
    content: "\f208"
}

.fa-angellist:before {
    content: "\f209"
}

.fa-cc:before {
    content: "\f20a"
}

.fa-shekel:before, .fa-sheqel:before, .fa-ils:before {
    content: "\f20b"
}

.fa-meanpath:before {
    content: "\f20c"
}

.fa-buysellads:before {
    content: "\f20d"
}

.fa-connectdevelop:before {
    content: "\f20e"
}

.fa-dashcube:before {
    content: "\f210"
}

.fa-forumbee:before {
    content: "\f211"
}

.fa-leanpub:before {
    content: "\f212"
}

.fa-sellsy:before {
    content: "\f213"
}

.fa-shirtsinbulk:before {
    content: "\f214"
}

.fa-simplybuilt:before {
    content: "\f215"
}

.fa-skyatlas:before {
    content: "\f216"
}

.fa-cart-plus:before {
    content: "\f217"
}

.fa-cart-arrow-down:before {
    content: "\f218"
}

.fa-diamond:before {
    content: "\f219"
}

.fa-ship:before {
    content: "\f21a"
}

.fa-user-secret:before {
    content: "\f21b"
}

.fa-motorcycle:before {
    content: "\f21c"
}

.fa-street-view:before {
    content: "\f21d"
}

.fa-heartbeat:before {
    content: "\f21e"
}

.fa-venus:before {
    content: "\f221"
}

.fa-mars:before {
    content: "\f222"
}

.fa-mercury:before {
    content: "\f223"
}

.fa-intersex:before, .fa-transgender:before {
    content: "\f224"
}

.fa-transgender-alt:before {
    content: "\f225"
}

.fa-venus-double:before {
    content: "\f226"
}

.fa-mars-double:before {
    content: "\f227"
}

.fa-venus-mars:before {
    content: "\f228"
}

.fa-mars-stroke:before {
    content: "\f229"
}

.fa-mars-stroke-v:before {
    content: "\f22a"
}

.fa-mars-stroke-h:before {
    content: "\f22b"
}

.fa-neuter:before {
    content: "\f22c"
}

.fa-genderless:before {
    content: "\f22d"
}

.fa-facebook-official:before {
    content: "\f230"
}

.fa-pinterest-p:before {
    content: "\f231"
}

.fa-whatsapp:before {
    content: "\f232"
}

.fa-server:before {
    content: "\f233"
}

.fa-user-plus:before {
    content: "\f234"
}

.fa-user-times:before {
    content: "\f235"
}

.fa-hotel:before, .fa-bed:before {
    content: "\f236"
}

.fa-viacoin:before {
    content: "\f237"
}

.fa-train:before {
    content: "\f238"
}

.fa-subway:before {
    content: "\f239"
}

.fa-medium:before {
    content: "\f23a"
}

.fa-yc:before, .fa-y-combinator:before {
    content: "\f23b"
}

.fa-optin-monster:before {
    content: "\f23c"
}

.fa-opencart:before {
    content: "\f23d"
}

.fa-expeditedssl:before {
    content: "\f23e"
}

.fa-battery-4:before, .fa-battery:before, .fa-battery-full:before {
    content: "\f240"
}

.fa-battery-3:before, .fa-battery-three-quarters:before {
    content: "\f241"
}

.fa-battery-2:before, .fa-battery-half:before {
    content: "\f242"
}

.fa-battery-1:before, .fa-battery-quarter:before {
    content: "\f243"
}

.fa-battery-0:before, .fa-battery-empty:before {
    content: "\f244"
}

.fa-mouse-pointer:before {
    content: "\f245"
}

.fa-i-cursor:before {
    content: "\f246"
}

.fa-object-group:before {
    content: "\f247"
}

.fa-object-ungroup:before {
    content: "\f248"
}

.fa-sticky-note:before {
    content: "\f249"
}

.fa-sticky-note-o:before {
    content: "\f24a"
}

.fa-cc-jcb:before {
    content: "\f24b"
}

.fa-cc-diners-club:before {
    content: "\f24c"
}

.fa-clone:before {
    content: "\f24d"
}

.fa-balance-scale:before {
    content: "\f24e"
}

.fa-hourglass-o:before {
    content: "\f250"
}

.fa-hourglass-1:before, .fa-hourglass-start:before {
    content: "\f251"
}

.fa-hourglass-2:before, .fa-hourglass-half:before {
    content: "\f252"
}

.fa-hourglass-3:before, .fa-hourglass-end:before {
    content: "\f253"
}

.fa-hourglass:before {
    content: "\f254"
}

.fa-hand-grab-o:before, .fa-hand-rock-o:before {
    content: "\f255"
}

.fa-hand-stop-o:before, .fa-hand-paper-o:before {
    content: "\f256"
}

.fa-hand-scissors-o:before {
    content: "\f257"
}

.fa-hand-lizard-o:before {
    content: "\f258"
}

.fa-hand-spock-o:before {
    content: "\f259"
}

.fa-hand-pointer-o:before {
    content: "\f25a"
}

.fa-hand-peace-o:before {
    content: "\f25b"
}

.fa-trademark:before {
    content: "\f25c"
}

.fa-registered:before {
    content: "\f25d"
}

.fa-creative-commons:before {
    content: "\f25e"
}

.fa-gg:before {
    content: "\f260"
}

.fa-gg-circle:before {
    content: "\f261"
}

.fa-tripadvisor:before {
    content: "\f262"
}

.fa-odnoklassniki:before {
    content: "\f263"
}

.fa-odnoklassniki-square:before {
    content: "\f264"
}

.fa-get-pocket:before {
    content: "\f265"
}

.fa-wikipedia-w:before {
    content: "\f266"
}

.fa-safari:before {
    content: "\f267"
}

.fa-chrome:before {
    content: "\f268"
}

.fa-firefox:before {
    content: "\f269"
}

.fa-opera:before {
    content: "\f26a"
}

.fa-internet-explorer:before {
    content: "\f26b"
}

.fa-tv:before, .fa-television:before {
    content: "\f26c"
}

.fa-contao:before {
    content: "\f26d"
}

.fa-500px:before {
    content: "\f26e"
}

.fa-amazon:before {
    content: "\f270"
}

.fa-calendar-plus-o:before {
    content: "\f271"
}

.fa-calendar-minus-o:before {
    content: "\f272"
}

.fa-calendar-times-o:before {
    content: "\f273"
}

.fa-calendar-check-o:before {
    content: "\f274"
}

.fa-industry:before {
    content: "\f275"
}

.fa-map-pin:before {
    content: "\f276"
}

.fa-map-signs:before {
    content: "\f277"
}

.fa-map-o:before {
    content: "\f278"
}

.fa-map:before {
    content: "\f279"
}

.fa-commenting:before {
    content: "\f27a"
}

.fa-commenting-o:before {
    content: "\f27b"
}

.fa-houzz:before {
    content: "\f27c"
}

.fa-vimeo:before {
    content: "\f27d"
}

.fa-black-tie:before {
    content: "\f27e"
}

.fa-fonticons:before {
    content: "\f280"
}

.fa-reddit-alien:before {
    content: "\f281"
}

.fa-edge:before {
    content: "\f282"
}

.fa-credit-card-alt:before {
    content: "\f283"
}

.fa-codiepie:before {
    content: "\f284"
}

.fa-modx:before {
    content: "\f285"
}

.fa-fort-awesome:before {
    content: "\f286"
}

.fa-usb:before {
    content: "\f287"
}

.fa-product-hunt:before {
    content: "\f288"
}

.fa-mixcloud:before {
    content: "\f289"
}

.fa-scribd:before {
    content: "\f28a"
}

.fa-pause-circle:before {
    content: "\f28b"
}

.fa-pause-circle-o:before {
    content: "\f28c"
}

.fa-stop-circle:before {
    content: "\f28d"
}

.fa-stop-circle-o:before {
    content: "\f28e"
}

.fa-shopping-bag:before {
    content: "\f290"
}

.fa-shopping-basket:before {
    content: "\f291"
}

.fa-hashtag:before {
    content: "\f292"
}

.fa-bluetooth:before {
    content: "\f293"
}

.fa-bluetooth-b:before {
    content: "\f294"
}

.fa-percent:before {
    content: "\f295"
}

.fa-gitlab:before {
    content: "\f296"
}

.fa-wpbeginner:before {
    content: "\f297"
}

.fa-wpforms:before {
    content: "\f298"
}

.fa-envira:before {
    content: "\f299"
}

.fa-universal-access:before {
    content: "\f29a"
}

.fa-wheelchair-alt:before {
    content: "\f29b"
}

.fa-question-circle-o:before {
    content: "\f29c"
}

.fa-blind:before {
    content: "\f29d"
}

.fa-audio-description:before {
    content: "\f29e"
}

.fa-volume-control-phone:before {
    content: "\f2a0"
}

.fa-braille:before {
    content: "\f2a1"
}

.fa-assistive-listening-systems:before {
    content: "\f2a2"
}

.fa-asl-interpreting:before, .fa-american-sign-language-interpreting:before {
    content: "\f2a3"
}

.fa-deafness:before, .fa-hard-of-hearing:before, .fa-deaf:before {
    content: "\f2a4"
}

.fa-glide:before {
    content: "\f2a5"
}

.fa-glide-g:before {
    content: "\f2a6"
}

.fa-signing:before, .fa-sign-language:before {
    content: "\f2a7"
}

.fa-low-vision:before {
    content: "\f2a8"
}

.fa-viadeo:before {
    content: "\f2a9"
}

.fa-viadeo-square:before {
    content: "\f2aa"
}

.fa-snapchat:before {
    content: "\f2ab"
}

.fa-snapchat-ghost:before {
    content: "\f2ac"
}

.fa-snapchat-square:before {
    content: "\f2ad"
}

.fa-pied-piper:before {
    content: "\f2ae"
}

.fa-first-order:before {
    content: "\f2b0"
}

.fa-yoast:before {
    content: "\f2b1"
}

.fa-themeisle:before {
    content: "\f2b2"
}

.fa-google-plus-circle:before, .fa-google-plus-official:before {
    content: "\f2b3"
}

.fa-fa:before, .fa-font-awesome:before {
    content: "\f2b4"
}

.fa-handshake-o:before {
    content: "\f2b5"
}

.fa-envelope-open:before {
    content: "\f2b6"
}

.fa-envelope-open-o:before {
    content: "\f2b7"
}

.fa-linode:before {
    content: "\f2b8"
}

.fa-address-book:before {
    content: "\f2b9"
}

.fa-address-book-o:before {
    content: "\f2ba"
}

.fa-vcard:before, .fa-address-card:before {
    content: "\f2bb"
}

.fa-vcard-o:before, .fa-address-card-o:before {
    content: "\f2bc"
}

.fa-user-circle:before {
    content: "\f2bd"
}

.fa-user-circle-o:before {
    content: "\f2be"
}

.fa-user-o:before {
    content: "\f2c0"
}

.fa-id-badge:before {
    content: "\f2c1"
}

.fa-drivers-license:before, .fa-id-card:before {
    content: "\f2c2"
}

.fa-drivers-license-o:before, .fa-id-card-o:before {
    content: "\f2c3"
}

.fa-quora:before {
    content: "\f2c4"
}

.fa-free-code-camp:before {
    content: "\f2c5"
}

.fa-telegram:before {
    content: "\f2c6"
}

.fa-thermometer-4:before, .fa-thermometer:before, .fa-thermometer-full:before {
    content: "\f2c7"
}

.fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
    content: "\f2c8"
}

.fa-thermometer-2:before, .fa-thermometer-half:before {
    content: "\f2c9"
}

.fa-thermometer-1:before, .fa-thermometer-quarter:before {
    content: "\f2ca"
}

.fa-thermometer-0:before, .fa-thermometer-empty:before {
    content: "\f2cb"
}

.fa-shower:before {
    content: "\f2cc"
}

.fa-bathtub:before, .fa-s15:before, .fa-bath:before {
    content: "\f2cd"
}

.fa-podcast:before {
    content: "\f2ce"
}

.fa-window-maximize:before {
    content: "\f2d0"
}

.fa-window-minimize:before {
    content: "\f2d1"
}

.fa-window-restore:before {
    content: "\f2d2"
}

.fa-times-rectangle:before, .fa-window-close:before {
    content: "\f2d3"
}

.fa-times-rectangle-o:before, .fa-window-close-o:before {
    content: "\f2d4"
}

.fa-bandcamp:before {
    content: "\f2d5"
}

.fa-grav:before {
    content: "\f2d6"
}

.fa-etsy:before {
    content: "\f2d7"
}

.fa-imdb:before {
    content: "\f2d8"
}

.fa-ravelry:before {
    content: "\f2d9"
}

.fa-eercast:before {
    content: "\f2da"
}

.fa-microchip:before {
    content: "\f2db"
}

.fa-snowflake-o:before {
    content: "\f2dc"
}

.fa-superpowers:before {
    content: "\f2dd"
}

.fa-wpexplorer:before {
    content: "\f2de"
}

.fa-meetup:before {
    content: "\f2e0"
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto
}

/*! CSS Used from: http://localhost:63342/g13/howitshouldbe/css/bootstrap.min.css */
*, ::after, ::before {
    box-sizing: border-box;
}

footer {
    display: block;
}

h2 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #FFAD2F;
    text-decoration: underline;
}

img {
    vertical-align: middle;
    border-style: none;
}

h2 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h2 {
    font-size: 2rem;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col-lg-2, .col-lg-4, .col-md-3, .col-sm-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 576px) {
    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media (min-width: 768px) {
    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}

@media (min-width: 992px) {
    .col-lg-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    }
}

.text-white {
    color: #fff !important;
}

@media print {
    *, ::after, ::before {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    img {
        page-break-inside: avoid;
    }

    h2, p {
        orphans: 3;
        widows: 3;
    }

    h2 {
        page-break-after: avoid;
    }

    .container {
        min-width: 992px !important;
    }
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fa-heart-o:before {
    content: "\f08a";
}

h2 {
    margin: 0;
    color: #322429;
    font-weight: 700;
}

h2 {
    font-size: 36px;
}

p {
    font-size: 15px;
    color: #6a6a6a;
    line-height: 2;
}

img {
    max-width: 100%;
}

a:hover, a:focus {
    text-decoration: none;
    outline: none;
}

ul {
    padding: 0;
    margin: 0;
}

.text-white h2, .text-white p, .text-white li, .text-white a {
    color: #fff;
}

.site-btn {
    display: inline-block;
    border: none;
    padding: 21px 44px;
    min-width: 156px;
    font-size: 16px;
    background: #FFAD2F;
    color: #fff;
    line-height: normal;
    cursor: pointer;
    text-transform: uppercase;
    text-align: center;
}

.site-btn:hover {
    color: #fff;
}

.footer-section {
    padding: 50px 0 0;
    background: #22242b;
}
.logoHolder{
    padding: 10px 10px 10px 10px;
}
.footer-widger {
    margin-bottom: 60px;
}

.footer-widger h2 {
    font-size: 18px;
    font-weight: 500;
    padding-top: 65px;
    margin-bottom: 33px;
}

.footer-widger ul {
    list-style: none;
}

.footer-widger ul li a {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 13px;
    opacity: 0.7;
}

.about-widget {
    margin-right: 41px;
    border-right: 2px solid #45484e;
}

.about-widget img {
    margin-bottom: 40px;
}

.about-widget .aw-text {
    max-width: 260px;
}

.about-widget .aw-text p {
    padding-right: 15px;
    margin-bottom: 35px;
}

.copyright {
    text-align: right;
    font-size: 12px;
    text-transform: uppercase;
    color: #676767;
    font-family: "Lato", sans-serif;
    padding: 5px 0 35px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1176px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .about-widget {
        margin-right: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-widget {
        border-right: none;
    }

    .about-widget .aw-text {
        max-width: 100%;
    }

    .footer-widger h2 {
        padding-top: 0;
    }
}

@media only screen and (max-width: 767px) {
    .about-widget {
        border-right: none;
    }

    .about-widget .aw-text {
        max-width: 100%;
    }

    .footer-widger h2 {
        padding-top: 0;
    }

    .copyright {
        text-align: center;
    }
}

@media only screen and (max-width: 479px) {
    .about-widget {
        margin-right: 0;
    }
}

/*! CSS Used from: http://localhost:63342/g13/howitshouldbe/css/bootstrap.min.css */
*,::after,::before{box-sizing:border-box;}
section{display:block;}
h2,h3{margin-top:0;margin-bottom:.5rem;}
p{margin-top:0;margin-bottom:1rem;}
a{color:#007bff;text-decoration:none;background-color:transparent;}
a:hover{color:#FFAD2F;text-decoration:underline;}
img{vertical-align:middle;border-style:none;}
h2,h3{margin-bottom:.5rem;font-weight:500;line-height:1.2;}
h2{font-size:2rem;}
h3{font-size:1.75rem;}
.container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
@media (min-width:576px){
    .container{max-width:540px;}
}
@media (min-width:768px){
    .container{max-width:720px;}
}
@media (min-width:992px){
    .container{max-width:960px;}
}
@media (min-width:1200px){
    .container{max-width:1140px;}
}
.col-lg-6{position:relative;width:100%;padding-right:15px;padding-left:15px;}
@media (min-width:992px){
    .col-lg-6{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
    .order-lg-1{-ms-flex-order:1;order:1;}
    .order-lg-2{-ms-flex-order:2;order:2;}
}
@media print{
    *,::after,::before{text-shadow:none!important;box-shadow:none!important;}
    a:not(.btn){text-decoration:underline;}
    img{page-break-inside:avoid;}
    h2,h3,p{orphans:3;widows:3;}
    h2,h3{page-break-after:avoid;}
    .container{min-width:992px!important;}
}
h2,h3{margin:0;color:#322429;font-weight:700;}
h2{font-size:36px;}
h3{font-size:30px;}
p{font-size:15px;color:#6a6a6a;line-height:2;}
img{max-width:100%;}
a:hover,a:focus{text-decoration:none;outline:none;}
.section-title{text-align:center;margin-bottom:65px;}
.section-title h2{font-size:72px;}
.spad{padding-top:50px;padding-bottom:110px;}
.intro-first{margin-bottom:98px;}
.about-text h3{margin-bottom:30px;}
.about-text p{margin-bottom:25px;}
.readmore-btn{display:inline-block;font-size:18px;font-weight:700;color:#FFAD2F;border-bottom:1px solid #FFAD2F;line-height:1;}
.readmore-btn:hover{color:#FFAD2F;}
@media (min-width: 1200px){
    .container{max-width:1176px;}
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
    .section-title h2{font-size:65px;}
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .about-text{margin-top:40px;}
    .section-title h2{font-size:55px;}
}
@media only screen and (max-width: 767px){
    .about-text{margin-top:40px;}
    .section-title h2{font-size:45px;}
}
@media only screen and (max-width: 479px){
    .section-title h2{font-size:38px;}
}


.navbar-toggle {
    background: transparent none;
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    border: 1px solid transparent;
    border-radius: 4px
}

.navbar-toggle:focus {
    outline: 0
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px
}

.navbar-toggle .icon-bar + .icon-bar {
    margin-top: 4px
}

@media (min-width: 768px) {
    .navbar-toggle {
        display: none
    }
}
h4{color:#FFAD2F;}
.head{
    margin-top: 30px;
    color:#FFAD2F;
    font-size: large;
    font-weight: bold;

}
.headerTitle{
    display:inline-block;
    font-size:27px;
    font-weight:700;
    color:#FFAD2F;}


button{margin:0;font:inherit;color:inherit;overflow:visible;text-transform:none;-webkit-appearance:button;cursor:pointer;font-family:inherit;font-size:inherit;line-height:inherit;}
button::-moz-focus-inner{padding:0;border:0;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
input{margin:0;font:inherit;color:inherit;font-family:inherit;font-size:inherit;line-height:inherit;line-height:normal;}
input::-moz-focus-inner{padding:0;border:0;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
.col-md-4,.col-sm-4,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
.col-xs-12{float:left;width:100%;}
@media (min-width:768px){
    .col-sm-4{float:left;width:33.33333333%;}
}
@media (min-width:992px){
    .col-md-4{float:left;width:33.33333333%;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
.btn,.text-center{text-align:center;}
.btn{display:inline-block;padding:6px 12px;margin-bottom:0;font-size:14px;font-weight:400;line-height:1.42857143;white-space:nowrap;vertical-align:middle;touch-action:manipulation;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background-image:none;border:1px solid transparent;border-radius:4px;}
.btn:active:focus,.btn:focus{outline:5px;outline-offset:-2px;}
.btn:focus,.btn:hover{color:#333;text-decoration:none;}
.btn:active{background-image:none;outline:0;box-shadow:inset 0 3px 5px rgba(0,0,0,.125);}
.btn{text-decoration:none!important;outline:none;-webkit-transition:all .3s ease-in-out;transition:all .3s ease-in-out;}
.btn-brd{border-radius:25px 0;}
.btn-light{padding:13px 40px;font-size:18px;
    border:2px solid #FFAD2F;
    color:#fff;background-color:#FFAD2F;}
.btn-light:focus,.btn-light:hover{border-color:#fd6802;
    color:#fff;background:#393939;}
@media (max-width:1000px){
    .btn-light{padding:13px 24px;}
}
button,input,textarea{margin:0;font:inherit;color:inherit;font-family:inherit;font-size:inherit;line-height:inherit;}
button{text-transform:none;}
button::-moz-focus-inner,input::-moz-focus-inner{padding:0;border:0;}
textarea{overflow:auto;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
.col-lg-4,.col-lg-12,.col-md-4,.col-md-8,.col-md-12,.col-sm-4,.col-sm-12,.col-xs-12{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
@media (min-width:768px){
    .col-sm-4,.col-sm-12{float:left;}
    .col-sm-12{width:100%;}
    .col-sm-4{width:33.33333333%;}
}
@media (min-width:992px){
    .col-md-4,.col-md-8,.col-md-12{float:left;}
    .col-md-12{width:100%;}
    .col-md-8{width:66.66666667%;}
    .col-md-4{width:33.33333333%;}
    .col-md-offset-2{margin-left:16.66666667%;}
}
@media (min-width:1200px){
    .col-lg-4,.col-lg-12{float:left;}
    .col-lg-12{width:100%;}
    .col-lg-4{width:33.33333333%;}
}
.errorText,label{display:inline-block;max-width:100%;margin-bottom:5px;font-weight:700;}
.errorText{color:red;}
.form-control{display:block;width:100%;height:34px;padding:6px 12px;font-size:14px;line-height:1.42857143;color:#555;background:#fff none;border:1px solid #ccc;border-radius:4px;box-shadow:inset 0 1px 1px rgba(0,0,0,.075);-webkit-transition:border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;-webkit-transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;transition:border-color .15s ease-in-out,box-shadow .15s ease-in-out;}
.form-control:focus{border-color:#66afe9;outline:0;box-shadow:inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);}
.form-control::-moz-placeholder{color:#999;opacity:1;}
.form-control:-ms-input-placeholder{color:#999;}
.form-control::-webkit-input-placeholder{color:#999;}
.form-control::-ms-expand{background-color:transparent;border:0;}
textarea.form-control{height:auto;}
.btn-block{display:block;width:100%;}
.contact_form{border:1px solid #ededed;box-shadow:0 5px 14px rgba(0,0,0,.1);background-color:#f2f3f5;padding:40px 30px;}
.contact_form .form-control{background-color:#fff;margin-bottom:30px;border:1px solid #ebebeb;box-sizing:border-box;color:#bcbcbc;font-size:16px;outline:0 none;padding:10px 25px;height:55px;resize:none;box-shadow:none!important;width:100%;}
.contact_form textarea{color:#bcbcbc;padding:20px 25px 25px!important;height:160px!important;}
.contact_form .form-control::-webkit-input-placeholder{color:#bcbcbc;}
.contact_form .form-control::-moz-placeholder{opacity:1;color:#bcbcbc;}
.contact_form .form-control::-ms-input-placeholder{color:#bcbcbc;}
@media (max-width:1000px){
    .btn-light{padding:13px 24px;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
@media (max-width:1000px){
    .btn-light{padding:13px 24px;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}

@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
*,:after,:before{box-sizing:border-box;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
    h3,p{orphans:3;widows:3;}
    h3{page-break-after:avoid;}
}
h3{font-family:inherit;line-height:1.1;margin:0;letter-spacing:0;position:relative;padding:0 0 10px;line-height:120%!important;}
p{margin:0 0 10px;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
    p{orphans:3;widows:3;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
    h3,p{orphans:3;widows:3;}
    h3{page-break-after:avoid;}
}
.col-md-10,.col-sm-10{position:relative;min-height:1px;padding-right:15px;padding-left:15px;}
@media (min-width:768px){
    .col-sm-10{float:left;}
    .col-sm-10{width:83.33333333%;}
    .col-sm-offset-1{margin-left:8.33333333%;}
}
@media (min-width:992px){
    .col-md-10{float:left;}
    .col-md-10{width:83.33333333%;}
    .col-md-offset-1{margin-left:23%;}
}
.icon{font-family:"'IcoMoon-Free'!important";speak:none;font-style:normal;font-weight:400;font-variant:normal;text-transform:none;line-height:1;letter-spacing:0;-webkit-font-feature-settings:"liga";-ms-font-feature-settings:"liga" 1;-o-font-feature-settings:"liga";font-feature-settings:"liga",normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.icon-headphones:before{content:"\e910";}
.fa-envelope:before{content:"\f0e0";}
.pd-add{padding:70px 0;}
.address-item{float:left;margin:0 11px;padding:0 15px;width:31%;min-width: 220px;box-shadow:0 5px 14px rgba(0,0,0,.1);}
.address-icon{padding:15px 0;}
.address-icon i{font-size:38px;color:#393939;}
.address-item h3{font-size:28px;font-weight:600;color:#FFAD2F;}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
    p{orphans:3;widows:3;}
}
@media print{
    *,:after,:before{color:#000!important;text-shadow:none!important;background:0 0!important;box-shadow:none!important;}
}
.hr1{
    position: relative;
    margin: 30px 0;
    border: 1px dashed #f2f3f5;
}